
import { Options, Vue } from 'vue-class-component';
import * as OM from "@/Model";
import { AdminClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';

@Options({
    components: {
    }
})
export default class Configuration extends Vue {

    config: OM.SystemConfiguration = new OM.SystemConfiguration();
    tempFaq: string = "";
    tempComeFunziona: string = "";

    created() {
        this.init()
    }

    init(){
        AdminClient.getAppConfiguration()
        .then( x => {
            this.config = x;
        })
    }

    save(){
        AdminClient.setAppConfiguration(this.config)
        .then( x => {
            this.init();
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("backoffice_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("backoffice_SalvatoConSuccesso", "Salvato con successo"),
                deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    add(){
        this.tempFaq = this.tempFaq.trim();

        if(!this.tempFaq)
            return;

        this.config.faq.push(this.tempFaq);
        this.tempFaq = "";
    }

    remove(index: number){
        this.config.faq.splice(index, 1);
    }

    addComeFunziona(){
        this.tempComeFunziona = this.tempComeFunziona.trim();

        if(!this.tempComeFunziona)
            return;

        this.config.comeFunziona.push(this.tempComeFunziona);
        this.tempComeFunziona = "";
    }

    removeComeFunziona(index: number){
        this.config.comeFunziona.splice(index, 1);
    }

}
